import styled from "styled-components"

// At the moment we don't have a design for the text editor using the new text field
// eslint-disable-next-line no-restricted-imports
import { TextField, TextFieldProps } from "@material-ui/core"

import { spacing } from "src/ui/spacing"

export function TextEditor({
  toolbar,
  onCaretMoved,
  ...props
}: {
  toolbar?: React.ReactNode
  onCaretMoved?: (pos: number) => void
} & TextFieldProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleCaret(e: any) {
    const selectionStart = e?.target?.selectionStart
    if (selectionStart != null) {
      onCaretMoved?.(selectionStart)
    }
  }

  return (
    <div>
      {!!toolbar && <Toolbar>{toolbar}</Toolbar>}
      <StyledTextField
        multiline
        minRows="7"
        maxRows="7"
        fullWidth
        inputProps={{
          onClick: handleCaret,
          onKeyUp: handleCaret,
        }}
        {...props}
      />
    </div>
  )
}

const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  border-radius: 8px 8px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: ${spacing.XS} ${spacing.XS2};
  border-bottom: 0;
`

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    padding: ${spacing.L};
    position: relative;
    border-radius: 0 0 8px 8px;
  }
`
